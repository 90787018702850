import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';
import { IoIosArrowBack } from 'react-icons/io';

const ShoppingTitle = props => {
  const { title, showActionBtn } = props;

  return (
    <div className="content">
      <div className="back_img">
        {showActionBtn ? (
          <IoIosArrowBack style={{ height: 20, width: 20 }} />
        ) : null}
      </div>
      <h1>{title}</h1>

      <style jsx>{`
        .content {
          margin: 0px 0 10px 0;
          text-align: center;
          position: relative;
        }

        .back_img {
          position: absolute;
        }

        h1 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 18px;
          line-height: 24px;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingTitle.propTypes = {
  title: PropTypes.string,
  showActionBtn: PropTypes.bool,
};

ShoppingTitle.defaultProps = {
  title: '',
  showActionBtn: '',
};

export default ShoppingTitle;
