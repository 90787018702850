import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';
import OrderFormBtn from '../components/orderFormBtn';

const ShoppingProgressNumber = props => {
  const { orderNumber, deliveryNumber } = props;
  return (
    <div className="content">
      <div>
        <div className="order-number">訂單編號：{orderNumber}</div>
        <div className="order-number">配送編號：{deliveryNumber}</div>
      </div>
      <div>
        <OrderFormBtn
          text="複製"
          btnStyle={{ color: '#6a318e', borderColor: '#6a318e' }}
        />
      </div>
      <style jsx>{`
        .content {
          margin-top: 36px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .order-number {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
          margin-bottom: 20px;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
          }
      `}</style>
    </div>
  );
};

ShoppingProgressNumber.propTypes = {
  orderNumber: PropTypes.string,
  deliveryNumber: PropTypes.string,
};

ShoppingProgressNumber.defaultProps = {
  orderNumber: '',
  deliveryNumber: '',
};

export default ShoppingProgressNumber;