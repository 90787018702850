import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const OrderFormBtn = props => {
  const { text, btnStyle } = props;

  return (
    <div style={btnStyle} className="content">
      {text}

      <style jsx>{`
        .content {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
          padding: 8px 10.5px;
          border-radius: 10px;
          border: 1px solid ${colors['black-p']};
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

OrderFormBtn.propTypes = {
  text: PropTypes.string,
  btnStyle: PropTypes.object,
};

OrderFormBtn.defaultProps = {
  text: '',
  btnStyle: {},
};

export default OrderFormBtn;
